/* eslint-disable no-useless-escape */
export const slugify = string =>
  string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

export const slugifyVacancy = (slug, city) =>
  `${slug}${city ? `-${slugify(city)}` : ''}`;
