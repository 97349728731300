import PropTypes from 'prop-types';

const YoutubeVideo = ({ youtubeId }) => {
  const videoEmbedUrl = `//www.youtube.com/embed/${youtubeId}`;

  return (
    <div className="c-video">
      <iframe
        loading="lazy"
        src={videoEmbedUrl}
        allowFullScreen
        className="c-video__iframe"
        title="Video"
      />
    </div>
  );
};

YoutubeVideo.propTypes = {
  youtubeId: PropTypes.string,
};

export default YoutubeVideo;
