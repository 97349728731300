import PropTypes from 'prop-types';

import Icon1 from 'svg/icon-car.svg';
import Icon2 from 'svg/icon-career.svg';
import Icon3 from 'svg/icon-cv.svg';
import Icon4 from 'svg/icon-wallet.svg';
import DefaultIcon from 'svg/icon-unknown.svg';

const USPIcon = ({ icon, className }) => {
  if (icon === 'ICON_1') return <Icon1 className={className} />;
  if (icon === 'ICON_2') return <Icon2 className={className} />;
  if (icon === 'ICON_3') return <Icon3 className={className} />;
  if (icon === 'ICON_4') return <Icon4 className={className} />;
  return <DefaultIcon />;
};

USPIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default USPIcon;
