import CompanyLogo from 'components/CompanyLogo';
import DisplayDate from 'components/DisplayDate';
import Properties from 'components/Properties';
import USPs from 'components/USPs';
import YoutubeVideo from 'components/YoutubeVideo';
import { useTranslation } from 'next-i18next';
import Pin from 'svg/icon-pin.svg';
import Clock from 'svg/icon-clock.svg';
import Mortarboard from 'svg/icon-mortarboard.svg';
import { formatNumber } from 'utils';
import { useMemo } from 'react';
import ApplyButton from '@atoms/ApplyButton/ApplyButton';

export default function JobPostingContent({ jobPosting, isPreview = false }) {
  const { t } = useTranslation();

  const {
    id,
    title,
    companyBranch: {
      slug: companySlug,
      company: companyName,
      logo: companyLogo,
    },
    logo,
    address,
    employmentType: { hours },
    educationLevel: { name: educationLevel },
    publishedAt,
    publishTo,
    category: { name: category },
    description,
    salaryMin,
    salaryMax,
    usps,
    youtubeId,
    applyUrl,
  } = jobPosting;

  const salary = useMemo(() => {
    // return empty string when variables not exist:
    if (!salaryMin && !salaryMax) return '';

    // Create label with available data:
    if (salaryMin && !salaryMax) {
      return `${t('property.salary')}: ${t('minimal')} € ${formatNumber(
        salaryMin
      )}`;
    }
    if (!salaryMin && salaryMax) {
      return `${t('property.salary')}: ${t('maximal')} € ${formatNumber(
        salaryMax
      )}`;
    }
    if (salaryMin && salaryMax) {
      return `${t('property.salary')}: € ${formatNumber(
        salaryMin
      )} - € ${formatNumber(salaryMax)}`;
    }
  }, [salaryMin, salaryMax]);

  return (
    <div>
      <h1 className="u-margin-bottom-none">
        {t('jobPostingDetail.pageHeading', { title })}
      </h1>
      {/* summary */}
      <div className="c-job-posting-detail__summary">
        <div className="o-layout o-layout--gutter-small">
          <div className="o-layout__cell o-layout__cell--fit">
            <Properties
              properties={[
                {
                  Icon: Pin,
                  label: t('property.location'),
                  href: `/vacatures/plaats/${address.location}`,
                  value: address.location,
                },
                {
                  Icon: Clock,
                  label: t('property.hours'),
                  value: hours,
                },
                {
                  Icon: Mortarboard,
                  label: t('property.location'),
                  value: educationLevel,
                },
              ]}
            />
          </div>
          <div className="o-layout__cell o-layout__cell--fit">
            <DisplayDate
              extraClasses="c-job-posting-detail__date"
              date={publishedAt}
              prefix={t('jobPostingDetail.publishFrom')}
            />

            {!!publishTo && (
              <DisplayDate
                extraClasses="c-job-posting-detail__date"
                date={publishTo}
                prefix={t('jobPostingDetail.publishTo')}
              />
            )}
          </div>
        </div>
      </div>
      {/* second summary? */}
      <Properties
        extraClasses="c-job-posting-detail__extra-properties"
        properties={[
          {
            label: t('property.company'),
            value: companyName,
            href: `/vacatures/${companySlug}`,
            noIcon: true,
          },
          {
            label: t('property.province'),
            value: address.state,
            noIcon: true,
          },
          {
            label: t('property.field'),
            value: category,
            noIcon: true,
          },
          {
            label: t('property.salary'),
            value: salary,
            noIcon: true,
          },
        ]}
      />
      {/* logo  */}
      {logo ? (
        <CompanyLogo
          company={{ logo, company: companyName }}
          extraClasses="c-job-posting-detail__logo"
          priority
        />
      ) : (
        <CompanyLogo
          company={{ logo: companyLogo, company: companyName }}
          extraClasses="c-job-posting-detail__logo"
          priority
        />
      )}
      {/* description */}
      <div
        className="c-job-posting-detail__description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {/* USPs */}
      {usps && Array.isArray(usps?.edges) && (
        <USPs usps={usps} companyName={companyName} />
      )}

      <div
        className="c-job-posting-detail__bottom"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('jobPostingDetail.punchline', {
            title,
            company: companyName,
            address: address.location,
          }),
        }}
      />
      {youtubeId && <YoutubeVideo youtubeId={youtubeId} />}

      {isPreview && <ApplyButton jobPostingId={id} href={applyUrl} />}
    </div>
  );
}
