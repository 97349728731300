import PropTypes from 'prop-types';
import USPIcon from 'components/USPIcon';
import { useTranslation } from 'next-i18next';

const USPs = ({ usps: { edges }, companyName }) => {
  const { t } = useTranslation();
  if (!edges || !edges.length) return null;

  return (
    <div className="c-usps">
      <h2 className="h3 c-usps__heading">
        {t('usps.heading', { companyName })}
      </h2>
      <ul className="c-usps__list">
        {edges.map(({ node: { id, icon, text } }) => (
          <li className="c-usps__usp" key={id}>
            <USPIcon icon={icon} className="c-usps__icon" />
            <p className="c-usps__description">{text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

USPs.propTypes = {
  usps: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape(PropTypes.shape({ node: PropTypes.object }))
    ),
  }),
  companyName: PropTypes.string.isRequired,
};

export default USPs;
