import DoubleChevronRight from 'svg/double-chevron-right.svg';
import ExternalLinkIcon from 'svg/icon-external-link.svg';
import Button from 'components/Button';
import { getStrippedEntityId } from 'lib/entityIds';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

export default function ApplyButton({ jobPostingId, href, fullWidth }) {
  const { t } = useTranslation();

  const applyLink = `${
    process.env.API_ENDPOINT
  }/vacancies/${getStrippedEntityId(jobPostingId)}/application/redirect`;

  return (
    <Button
      label={t('sidebar.cta')}
      Tag="a"
      href={applyLink}
      target="_blank"
      extraClasses={`${fullWidth ? 'c-button--full-width' : ''} c-sidebar__cta`}
      Icon={href ? ExternalLinkIcon : DoubleChevronRight}
      rel="nofollow"
    />
  );
}

ApplyButton.propTypes = {
  jobPostingId: PropTypes.string.isRequired,
  href: PropTypes.string,
  fullWidth: PropTypes.bool,
};
